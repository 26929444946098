export const RoutesEndpoint = {
    ACTIVE_CONTEST_LIST: '/active-contest-list',
    CONTEST: (contest_id: number) => `/contest/${contest_id}`,
    RULES: '/rules',
    CONTESTS_HISTORY: '/contests-history',
    NEW_CONTEST_HISTORY: (contest_id?: number) => `/new-contest-history${contest_id ? '/' + contest_id : ''}`,
    SPONSORS: '/sponsors',
    SPONSOR_RATING: '/sponsor-rating',
    USER_PANEL: () => `/user-panel`,
    REGISTRATION_PARTICIPANTS: '/registration-participants',
    ADMIN_PANEL: '/admin-panel/admin',
    ADMIN_CREATE_CONTEST: '/admin-panel/create-contest',
    ADMIN_EDIT_RULES: '/admin-panel/edit-rules',
    PREVIEW_CONTEST: (contest_id: number) => `/contest/preview-contest/${contest_id}`,
};

export type USER_PANEL_TABS = 'sponsor' | 'contestList';
