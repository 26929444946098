'use client';

import { createContext, memo, NamedExoticComponent, useContext, useEffect, useMemo, useState } from 'react';
import { CustomWebApp } from '@/lib/types/TelegramTypes';
import { setCookie } from 'cookies-next';

export interface ITelegramContext extends CustomWebApp {}

export const TelegramContext = createContext<ITelegramContext>({
    isReady: false,
} as ITelegramContext);

const { Provider } = TelegramContext;

interface ITelegramContextProvider {
    children: React.ReactNode;
}

export const _TelegramProvider: NamedExoticComponent<ITelegramContextProvider> = memo(({ children }) => {
    const [webApp, setWebApp] = useState<CustomWebApp>({} as CustomWebApp);
    const [isReady, setIsReady] = useState<boolean>(false);

    useEffect(() => {
        const app = window.Telegram.WebApp;

        if (app) {
            app.ready();
            setIsReady(true);
            setWebApp(app);

            setCookie('apiKey', app.initData);
            setCookie('user_id', app.initDataUnsafe?.user?.id);
            setCookie('tg-version', app.version);
            setCookie('platform', app.platform);

            // Init Settings
            if (!app.isExpanded) {
                app.expand();
            }
            app.disableVerticalSwipes();
            app.setHeaderColor('#000000');
        }
    }, []);

    const value = useMemo(() => {
        return { ...webApp, isReady };
    }, [webApp, isReady]);

    return <Provider value={value}>{children}</Provider>;
});

export const useTelegram = () => useContext(TelegramContext);
